import React, { createContext, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { Typography } from "@mui/material";
import config from "../utils/settings";
import useAuth from "../hooks/useAuth";
import useUnload from "../hooks/useUnload";
import usePage from "../hooks/usePage";
import { LineApi } from "../services/http";
import { parseUrl } from "../utils/parseUrl";
import { TASHKEEL_STATUS } from "../enums";
import { getNavigationParams } from "../utils/navigateParams";

const initialDataState = {
  folders: [],
  printsType: [],
  folder: {},
  listPages: {},
  listPagesPagination: {},
  pages: [],
  pagesPagination: {},
  lineTypes: [],
  loading: false,
  newFolder: {},
  foldersPagination: {},
  searchText: null,
  image: {},
  imagesLines: [],
  imageEl: {},
  offers: {},
  updatedLines: [],
  currentAction: null,
  folderId: null,
  page: {},
  index: null,
  folderAction: null,
  imageAction: null,
  currentFolder: {},
  searchablePdf: null,
  loadingData: 25,
  socketData: [],
  error: {},
  line: null,
  newLine: null,
  lines: undefined,
  startConvert: false,
  startExport: false,
  fetchError: null,
  workspace: false,
  newText: [],
  idsToTranscribe: undefined,
  retrain: null,
  folderInProgress: null,
  uploadAction: null,
  foldersIds: [],
  uploadedFolderId: null,
  mousePos: {},
  details: [],
  userCountry: null,
  paymentToken: null,
  stripeUrl: null,
  stripeSessionId: null,
  finalPrice: 0,
  lastText: [],
  canShowDialogLeavingPage: false,
  canShowDialogLeavingPageOnUpload: [],
  imageDimensions: {},
  displayMode: "horizontal",
  firstHandleChange: false,
  reorderState: false,
  reorderMode: false,
  docId: null,
  progressCount: 0,
  selectedPage: [],
  editSegmentation: false,
  displayPolygons: false,
  displayRegions: false,
  displayBaselines: false,
  editBaselines: false,
  receivedObjects: [],
  addBaselines: false,
  selectedBaseline: [],
  selectedAnchorPoints: [],
  baselineIndex: null,
  socketMessages: [],
  failedReceivedObjects: [],
  uploadingFolders: {},
  pageView: "workspace",
  imageTag: null,
  mode: "editText",
  detectClick: false,
  linesWithTashkeel: [],
  tashkeelStatus: TASHKEEL_STATUS.NONE,
  tashkeelLoadings: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_PRINTS_TYPE": {
      const { results } = action.payload;
      return {
        ...state,
        printsType: results,
      };
    }
    case "GET_FOLDERS": {
      const { folders, foldersPagination, searchText } = action.payload;
      return {
        ...state,
        folders,
        foldersPagination,
        searchText,
      };
    }
    case "GET_SINGLE_FOLDER": {
      const { folder } = action.payload;
      return {
        ...state,
        folder,
      };
    }
    case "DELETE_FOLDER": {
      const { results } = action.payload;
      return {
        ...state,
        folder: results,
      };
    }
    case "GET_NEW_FOLDER": {
      const { results } = action.payload;
      return {
        ...state,
        newFolder: results,
      };
    }
    case "GET_SINGLE_PAGE_BY_FOLDER_ID": {
      const { listPages, listPagesPagination } = action.payload;
      return {
        ...state,
        listPages,
        listPagesPagination,
      };
    }
    case "GET_PAGES_BY_FOLDER_ID": {
      const { pages, pagesPagination } = action.payload;
      return {
        ...state,
        pages,
        pagesPagination,
      };
    }
    case "GET_SINGLE_IMAGE": {
      const { image } = action.payload;
      return {
        ...state,
        image,
      };
    }

    case "GET_LINE_TYPES": {
      const { results } = action.payload;
      return {
        ...state,
        lineTypes: results,
      };
    }
    case "SET_LOADER": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "CONVERT_IMAGES": {
      const { results } = action.payload;
      return {
        ...state,
        imagesLines: results,
      };
    }
    case "SET_IMAGE": {
      return {
        ...state,
        imageEl: action.payload,
      };
    }
    case "GET_OFFERS": {
      const { results } = action.payload;
      return {
        ...state,
        offers: results,
      };
    }
    case "UPDATE_LINES": {
      return {
        ...state,
        lines: action.payload,
      };
    }
    case "SET_CURRENT_ACTION": {
      return {
        ...state,
        currentAction: action.payload,
      };
    }
    case "MARK_IMAGE_AS_DONE": {
      return {
        ...state,
      };
    }
    case "DELETE_IMAGES": {
      return {
        ...state,
      };
    }
    case "SET_FOLDER_ID": {
      return {
        ...state,
        folderId: action.payload,
      };
    }
    case "SET_PAGE": {
      return {
        ...state,
        page: action.payload,
      };
    }
    case "SET_INDEX_PAGE": {
      return {
        ...state,
        index: action.payload,
      };
    }
    case "SET_FOLDER_ACTION": {
      return {
        ...state,
        folderAction: action.payload,
      };
    }
    case "SET_IMAGE_ACTION": {
      return {
        ...state,
        imageAction: action.payload,
      };
    }
    case "SET_NUMBER_PAGES": {
      return {
        ...state,
        currentFolder: action.payload,
      };
    }
    case "EXPORT_PDF": {
      const { results } = action.payload;
      return {
        ...state,
        searchablePdf: results,
      };
    }
    case "SET_LOADING_DATA": {
      return {
        ...state,
        loadingData: action.payload,
      };
    }
    case "SOCKET_CONNECTION_DATA": {
      return {
        ...state,
        socketData: action.payload,
      };
    }
    case "SET_RECEIVED_OBJECTS": {
      const indexToUpdate = state?.receivedObjects?.findIndex(
        (item) => item?.body?.folderId === action?.payload?.body?.folderId,
      );
      let newReceivedObjects = state?.receivedObjects?.map((elt) =>
        elt?.body?.folderId === action?.payload?.body?.folderId
          ? action?.payload
          : elt,
      );
      const newPages = state?.pages?.map((elt) =>
        elt._id === action?.payload?.body?._id ? action?.payload?.body : elt,
      );

      return {
        ...state,
        receivedObjects:
          indexToUpdate !== -1 && action.payload.type === "upload"
            ? newReceivedObjects
            : [...state.receivedObjects, action.payload],
        pages: action.payload.type === "prediction" ? newPages : state.pages,
      };
    }
    case "RESET_RECEIVED_OBJECT": {
      return { ...state, receivedObjects: [] };
    }
    case "SET_FAILED_RECEIVED_OBJECTS": {
      return {
        ...state,
        failedReceivedObjects: [...state.failedReceivedObjects, action.payload],
      };
    }
    case "RESET_FAILED_RECEIVED_OBJECT": {
      return { ...state, failedReceivedObjects: [] };
    }
    case "SET_ERROR": {
      const err = action.payload;
      return {
        ...state,
        error: err,
      };
    }
    case "UPDATE_SINGLE_IMAGE": {
      const { results } = action.payload;
      return {
        ...state,
        pages: results,
      };
    }
    case "ADD_NEW_LINE": {
      const { results } = action.payload;
      return {
        ...state,
        newLine: results,
      };
    }
    case "DELETE_LINE": {
      const { results } = action.payload;
      return {
        ...state,
        line: results,
      };
    }
    case "START_CONVERT_PAGES": {
      return {
        ...state,
        startConvert: action.payload,
      };
    }
    case "START_EXPORT_PDF": {
      return {
        ...state,
        startExport: action.payload,
      };
    }
    case "UPDATE_SINGLE_FOLDER": {
      const { results } = action.payload;
      return {
        ...state,
        folders: results,
      };
    }
    case "SET_ERROR_DATA": {
      return {
        ...state,
        fetchError: action.payload,
      };
    }
    case "GO_TO_WORKSPACE": {
      return {
        ...state,
        workspace: action.payload,
      };
    }

    case "SET_NEW_TXT": {
      return {
        ...state,
        newText: action.payload,
      };
    }
    case "SET_IMAGES_IDS_TRANSCRIPTION_IN_PROGRESS": {
      return {
        ...state,
        idsToTranscribe: state?.idsToTranscribe?.length
          ? [...state?.idsToTranscribe, ...action.payload]
          : action.payload,
      };
    }
    case "REMOVE_IMAGES_IDS_TRANSCRIPTION_DONE": {
      return {
        ...state,
        idsToTranscribe: action.payload,
      };
    }
    case "SET_RETRAIN": {
      return {
        ...state,
        retrain: action.payload,
      };
    }
    case "START_PREPARE_PDF": {
      return {
        ...state,
        folderInProgress: action.payload,
      };
    }
    case "SET_UPLOAD_ACTION": {
      return {
        ...state,
        uploadAction: action.payload,
      };
    }
    case "SET_FOLDERS_IDS": {
      return {
        ...state,
        foldersIds: action.payload,
      };
    }
    case "SET_UPLOADED_FOLDER_ID": {
      return {
        ...state,
        uploadedFolderId: action.payload,
      };
    }
    case "SET_MOUSE_POS": {
      return {
        ...state,
        mousePos: action.payload,
      };
    }
    case "SET_AFFILIATION_DETAILS": {
      return {
        ...state,
        details: action.payload,
      };
    }
    case "SET_USER_COUNTRY": {
      return {
        ...state,
        userCountry: action.payload,
      };
    }
    case "SET_PAYMENT_TOKEN": {
      return {
        ...state,
        paymentToken: action.payload,
      };
    }
    case "SET_STRIPE_URL": {
      return {
        ...state,
        stripeUrl: action.payload,
      };
    }
    case "SET_STRIPE_SESSION_ID": {
      return {
        ...state,
        stripeSessionId: action.payload,
      };
    }
    case "SET_FINAL_PRICE": {
      return {
        ...state,
        finalPrice: action.payload,
      };
    }
    case "SAVE_LAST_TEXT": {
      return {
        ...state,
        lastText: action.payload,
      };
    }
    case "SET_CAN_DIALOG_LEAVING_PAGE": {
      return {
        ...state,
        canShowDialogLeavingPage: action.payload,
      };
    }
    case "SET_CAN_DIALOG_LEAVING_PAGE_ON_UPLOAD": {
      return {
        ...state,
        canShowDialogLeavingPageOnUpload: action.payload,
      };
    }
    case "SET_IMAGE_DIMENSIONS": {
      return {
        ...state,
        imageDimensions: action.payload,
      };
    }
    case "SET_DISPLAY_MODE": {
      return {
        ...state,
        displayMode: action.payload,
      };
    }
    case "SET_LINES": {
      return {
        ...state,
        lines: action.payload,
      };
    }
    case "DETECT_FIRST_EDIT": {
      return {
        ...state,
        firstHandleChange: action.payload,
      };
    }
    case "SET_REORDER_STATE": {
      return {
        ...state,
        reorderState: action.payload,
      };
    }
    case "SET_REORDER_MODE": {
      return {
        ...state,
        reorderMode: action.payload,
      };
    }
    case "SET_DOCUMENT_ID": {
      return {
        ...state,
        docId: action.payload,
      };
    }
    case "SET_PROGRESS_COUNT": {
      return {
        ...state,
        progressCount: action.payload,
      };
    }
    case "SET_IMAGES_IDS_TO_TRANSCRIBE": {
      return {
        ...state,
        selectedPage: action.payload,
      };
    }
    case "SET_EDIT_SEGMENTATION": {
      return {
        ...state,
        editSegmentation: action.payload,
      };
    }
    case "SET_TEXSTS_TASHKEEL": {
      //TODO: to verify with Karama
      return {
        ...state,
        lines: state.lines.map((line, index) => {
          return { ...line, text: action.payload[index] };
        }),
      };
    }
    case "SET_UPLOADING_FOLDERS": {
      return {
        ...state,
        uploadingFolders: {
          ...state.uploadingFolders,
          [action.payload.folderId]: action.payload.percentage,
        },
      };
    }
    case "REMOVING_UPLOADED_FOLDER": {
      let newUploadingFolders = state.uploadingFolders;
      delete newUploadingFolders[action.payload];
      return {
        ...state,
        uploadingFolders: { ...newUploadingFolders },
      };
    }
    case "SET_PAGE_VIEW": {
      return {
        ...state,
        pageView: action.payload,
      };
    }
    case "SET_KONVA_IMAGE": {
      return {
        ...state,
        imageTag: action.payload,
      };
    }
    case "SET_MODE": {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case "SET_DETECT_CLICK": {
      return {
        ...state,
        detectClick: action.payload,
      };
    }
    case "SET_TASHKEEL_STATUS": {
      return {
        ...state,
        tashkeelStatus: action.payload,
      };
    }
    case "SET_TASHKEEL_LOADINGS": {
      return {
        ...state,
        tashkeelLoadings: [...state.tashkeelLoadings, action.payload],
      };
    }
    case "REMOVE_TASHKEEL_LOADINGS": {
      return {
        ...state,
        tashkeelLoadings: state.tashkeelLoadings.filter(
          (id) => id !== action.payload,
        ),
      };
    }
    case "UPDATE_LINE_POLYGON": {
      const index = state?.lines?.findIndex(
        (obj1) => obj1.id === action.payload.body._id,
      );
      if (index !== -1) {
        state.lines[index].polygon = action.payload.body.polygon;
        state.lines[index].text = action.payload.body.text;
      }
    }

    default: {
      return { ...state };
    }
  }
};
const DataContext = createContext({
  ...initialDataState,
  getFolders: () => Promise.resolve(),
  getPrintsType: () => Promise.resolve(),
  getSingleFolder: () => Promise.resolve(),
  deleteFolder: () => Promise.resolve(),
  getSinglePageByFolderId: () => Promise.resolve(),
  createNewFolder: () => Promise.resolve(),
  uploadFile: () => Promise.resolve(),
  getPagesByFolderId: () => Promise.resolve(),
  getLineTypes: () => Promise.resolve(),
  isLoading: () => Promise.resolve(),
  getSingleImage: () => Promise.resolve(),
  convertImages: () => Promise.resolve(),
  setImage: () => Promise.resolve(),
  getOffers: () => Promise.resolve(),
  updateLines: () => Promise.resolve(),
  setCurrentAction: () => Promise.resolve(),
  markImageAsDone: () => Promise.resolve(),
  deleteImages: () => Promise.resolve(),
  setFolderId: () => Promise.resolve(),
  setPage: () => Promise.resolve(),
  setIndexPage: () => Promise.resolve(),
  setFolderAction: () => Promise.resolve(),
  setImageCurrentAction: () => Promise.resolve(),
  setCurrentFolder: () => Promise.resolve(),
  exportText: () => Promise.resolve(),
  exportPDF: () => Promise.resolve(),
  setloadingData: () => Promise.resolve(),
  setSocketData: () => Promise.resolve(),
  setError: () => Promise.resolve(),
  updateSingleImage: () => Promise.resolve(),
  addLine: () => Promise.resolve(),
  deleteLine: () => Promise.resolve(),
  getLines: () => Promise.resolve(),
  startConvertPages: () => Promise.resolve(),
  startExportPdf: () => Promise.resolve(),
  setErrorData: () => Promise.resolve(),
  goToWorkspace: () => Promise.resolve(),
  setNewText: () => Promise.resolve(),
  setImageIds: () => Promise.resolve(),
  updateFolder: () => Promise.resolve(),
  setRetrain: () => Promise.resolve(),
  setRetrainStatus: () => Promise.resolve(),
  setFolderNotif: () => Promise.resolve(),
  exportDoc: () => Promise.resolve(),
  startPreparePdf: () => Promise.resolve(),
  setUploadAction: () => Promise.resolve(),
  setFoldersIds: () => Promise.resolve(),
  setUploadedFolderId: () => Promise.resolve(),
  updateText: () => Promise.resolve(),
  exportFile: () => Promise.resolve(),
  setMousePos: () => Promise.resolve(),
  storeAffiliationDetails: () => Promise.resolve(),
  setPaymentToken: () => Promise.resolve(),
  setStripeUrl: () => Promise.resolve(),
  setStripeSessionId: () => Promise.resolve(),
  setFinalPrice: () => Promise.resolve(),
  saveLastText: () => Promise.resolve(),
  setCanShowDialogLeavingPage: () => Promise.resolve(),
  setCanShowDialogLeavingPageOnUpload: () => Promise.resolve(),
  setImageDimensions: () => Promise.resolve(),
  setDisplayMode: () => Promise.resolve(),
  setLines: () => Promise.resolve(),
  handleSave: () => Promise.resolve(),
  detectFirstHandleChange: () => Promise.resolve(),
  setReorderState: () => Promise.resolve(),
  setReorderMode: () => Promise.resolve(),
  getPagesByDocumentId: () => Promise.resolve(),
  getFolderByDocumentId: () => Promise.resolve(),
  getSingleImageByDocumentIdAndImageId: () => Promise.resolve(),
  setProgressCount: () => Promise.resolve(),
  setSelectedPage: () => Promise.resolve(),
  updateImageNotif: () => Promise.resolve(),
  setReceivedObjects: () => Promise.resolve(),
  resetReceivedObjects: () => Promise.resolve(),
  setFailedReceivedObjects: () => Promise.resolve(),
  resetFailedReceivedObjects: () => Promise.resolve(),
  disconnectFromStomp: () => Promise.resolve(),
  removeImagesIdsTranscriptionDone: () => Promise.resolve(),
  setUploadingFolders: () => Promise.resolve(),
  removeUploadedFolder: () => Promise.resolve(),
  setPageView: () => Promise.resolve(),
  setKonvaImage: () => Promise.resolve(),
  setMode: () => Promise.resolve(),
  setDetectClick: () => Promise.resolve(),
  tashkeelTexts: () => Promise.resolve(),
  setTashkeelStatus: () => Promise.resolve(),
  setTashkeelLoadings: () => Promise.resolve(),
  removeTashkeelLoadings: () => Promise.resolve(),
  saveNewText: () => Promise.resolve(),
});

export const DataProvider = ({ children, stompClientService }) => {
  const [state, dispatch] = useReducer(reducer, initialDataState);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getUserInfo, user, setOnboardingData } = useAuth();

  const { setRegions } = usePage();

  const navigate = useNavigate();

  useEffect(() => {
    axios.post(config.REACT_APP_BASE_URL + "/user/subscribe").then((res) => {
      localStorage.setItem("stompQueue", res.data.sessionId);
      stompClientService.subscribe(
        `/queue/${localStorage.getItem("stompQueue")}`,
        async (message) => {
          if (message.body.Status === "Fail") {
            setFailedReceivedObjects(message);
            if (message.body.ImageID) {
              getSnackbar(
                `${t("weCannotPredictThisPage")} ${
                  Math.ceil(message.body.ImageID.split("-")[1]) +
                  (Math.ceil(message.body.ImageID.split("-")[2])
                    ? `_(${Math.ceil(message.body.ImageID.split("-")[2])})`
                    : null)
                } ${t("pleaseTryAgain")}`,
                "error",
              );
            }
            if (message.type === "upload") {
              getSnackbar("errorUploadFile", "error");
              getFolders(1, 25, "", "afterFailUpload");
            }
          }
          setReceivedObjects(message);
          if (message.type === "calculate-mask") {
            updateLine(message);
            setSocketData(message);
            setNewText([
              {
                index: message.body.index,
                lineId: message.body._id,
                newVersion: message.body.text,
                polygon: message.body.polygon,
              },
            ]);
            return;
          }
          if (message.type !== "prediction" && message.type !== "upload") {
            setSocketData(message);
            return;
          }
          setReceivedObjects(message);
          if (message.type === "upload") {
            setReceivedObjects(message);
            let receivedObjectsProgress = JSON.parse(
              localStorage.getItem("receivedObjectsProgress"),
            );
            localStorage.setItem(
              "receivedObjectsProgress",
              JSON.stringify({
                ...receivedObjectsProgress,
                [message.body.folderId]: message.body.isReady,
              }),
            );

            if (message.body.isReady === 100) {
              getSnackbar(
                "successUploadFile",
                "success",
                "descriptionSuccessUploadSnackbar",
              );
              delete receivedObjectsProgress[message.body.folderId];
              localStorage.setItem(
                "receivedObjectsProgress",
                JSON.stringify({ ...receivedObjectsProgress }),
              );
            }
          }
        },
      );
    });
    axios.get(config.REACT_APP_BASE_URL + "/retrain").then((res) => {
      sessionStorage.setItem(
        "retrainSteps",
        JSON.stringify(res.data.retrainSteps),
      );
    });
  }, []);

  const setReceivedObjects = (message) => {
    dispatch({ type: "SET_RECEIVED_OBJECTS", payload: message });
  };
  const updateLine = (message) => {
    dispatch({ type: "UPDATE_LINE_POLYGON", payload: message });
  };

  const resetReceivedObjects = () => {
    dispatch({
      type: "RESET_RECEIVED_OBJECT",
    });
  };

  const setFailedReceivedObjects = (message) => {
    dispatch({ type: "SET_FAILED_RECEIVED_OBJECTS", payload: message });
  };
  const resetFailedReceivedObjects = () => {
    dispatch({
      type: "RESET_FAILED_RECEIVED_OBJECT",
    });
  };

  const getSnackbar = (message, status, description) => {
    enqueueSnackbar(
      <Typography variant="body2" className="snackbar-msg">
        {t(message)}
        {description ? (
          <>
            <br />
            {t(description)}
          </>
        ) : null}
      </Typography>,
      {
        variant: status,
        autoHideDuration:
          status === "error" ||
          message === "successUploadFile" ||
          message === "successTranscriptionPages"
            ? null
            : 5000,
      },
    );
  };

  const getPrintsType = async () => {
    setErrorData(null);
    isLoading(true);
    setCurrentAction("getDocumentTypes");
    await axios
      .get(`${config.REACT_APP_BASE_URL}/documents-types`)
      .then((response) => {
        isLoading(false);
        setCurrentAction(null);
        const results = response.data;
        dispatch({
          type: "GET_PRINTS_TYPE",
          payload: {
            results,
          },
        });
      })
      .catch(async (err) => {
        setErrorData("getDocTypesError");
        isLoading(false);
        setCurrentAction(null);
      });
  };
  const getFolders = async (page, limit, searchText, currentAction) => {
    goToWorkspace(false);
    setErrorData(null);
    isLoading(true);
    setCurrentAction(`getFolders${currentAction}`);
    const params = {
      page: page || 1,
      limit: limit || 25,
      search: searchText,
    };
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder`, {
        params,
      })
      .then((response) => {
        const results = response.data;
        isLoading(false);
        setCurrentAction(null);
        dispatch({
          type: "GET_FOLDERS",
          payload: {
            folders: results.data,
            foldersPagination: results.meta,
            searchText,
          },
        });
        localStorage.setItem("foldersPagination", JSON.stringify(results.meta));
        localStorage.setItem("pagesPagination", JSON.stringify({}));
      })
      .catch(async (err) => {
        setErrorData("getFoldersError");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const getSingleFolder = async (id, action) => {
    setErrorData(null);
    isLoading(true);
    setFolderAction("getSingleFolder");
    const setItemInPlace = (data) => {
      const results = state.folders?.map((elt) =>
        elt._id === id ? data : elt,
      );
      dispatch({
        type: "GET_FOLDERS",
        payload: {
          folders: results,
        },
      });
    };
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/${id}`)
      .then(async (response) => {
        localStorage.setItem(
          "docId",
          JSON.stringify(response?.data?.data?.documentID),
        );

        isLoading(false);
        setFolderAction(null);
        dispatch({
          type: "GET_SINGLE_FOLDER",
          payload: {
            folder: response.data,
          },
        });
        setItemInPlace(response.data.data);
        setCurrentFolder(response.data.data);
      })
      .catch(async (err) => {
        setErrorData("getFolderByIdError");
        isLoading(false);
        setFolderAction(null);
      });
  };
  const getFolderByDocumentId = async (id, action) => {
    setErrorData(null);
    isLoading(true);
    setFolderAction("getSingleFolder");
    if (action === "getSingleImage") {
      setImageCurrentAction("getSingleImage");
    }
    const setItemInPlace = (data) => {
      const results = state.folders?.map((elt) =>
        elt.documentID[0] === id ? data : elt,
      );
      dispatch({
        type: "UPDATE_SINGLE_FOLDER",
        payload: {
          results,
        },
      });
    };
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/document/${id}`)
      .then(async (response) => {
        localStorage.setItem(
          "docId",
          JSON.stringify(response?.data?.data?.documentID),
        );

        isLoading(false);
        setFolderAction(null);
        dispatch({
          type: "GET_SINGLE_FOLDER",
          payload: {
            folder: response.data,
          },
        });
        setItemInPlace(response.data.data);
        setCurrentFolder(response.data.data);
      })
      .catch(async (err) => {
        if (err.response.data.message === "No folder found with that id.") {
          setErrorData("folderNotFoundWiththatId");
        } else {
          setErrorData("getFolderByIdError");
        }
        isLoading(false);
        setFolderAction(null);
      });
  };
  const updateFolder = async (id) => {
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/${id}`)
      .then(async (response) => {
        dispatch({
          type: "GET_SINGLE_FOLDER",
          payload: {
            folder: response.data,
          },
        });
      })
      .catch(async (err) => {});
  };

  const deleteFolder = async (id, currentAction) => {
    isLoading(true);
    setCurrentAction("deleteFolder");
    await axios
      .delete(`${config.REACT_APP_BASE_URL}/folder/${id}`)
      .then(async (response) => {
        if (currentAction !== "deleteAfterUpload") {
          getSnackbar("successDeleteFolder", "success");
          setCanShowDialogLeavingPageOnUpload(
            state?.canShowDialogLeavingPageOnUpload.filter((elt) => elt !== id),
          );
        }
        isLoading(false);
        setCurrentAction(null);
        let pagination =
          !state.foldersPagination?.nextPage &&
          state.foldersPagination?.totalCount % 25 === 1
            ? state.foldersPagination?.prevPage
            : state.foldersPagination?.currentPage;
        getFolders(
          pagination,
          state.foldersPagination.limit,
          state.searchText,
          "afterDelete",
        );
      })
      .catch(async (err) => {
        getSnackbar("errorDeleteFolder", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const getPagesByFolderId = async (id, page, limit) => {
    dispatch({
      type: "GET_PAGES_BY_FOLDER_ID",
      payload: {
        pages: [],
        pagesPagination: {},
      },
    });
    isLoading(true);
    setCurrentAction("getPagesByFolderId");
    setSelectedPage([]);
    resetReceivedObjects();
    resetFailedReceivedObjects();
    const params = {
      page: page || 1,
      limit: limit || 25,
    };
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/${id}/image`, { params })
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "GET_PAGES_BY_FOLDER_ID",
          payload: {
            pages: results.data,
            pagesPagination: results.meta,
          },
        });
        if (results.data.errorMessage === "Lambda timeout.") {
          localStorage.setItem("pagesPagination", JSON.stringify({}));
        } else {
          localStorage.setItem("pagesPagination", JSON.stringify(results.meta));
        }
        localStorage.setItem(
          "cardsNb",
          JSON.stringify(
            results.meta.totalCount - results.meta.currentPage * 25 > 25
              ? 25
              : results.meta.totalCount - results.meta.currentPage * 25 < 1
                ? 25
                : results.meta.totalCount - results.meta.currentPage * 25,
          ),
        );

        isLoading(false);
        setCurrentAction(null);
      })

      .catch(async (err) => {
        if (err.message !== "Request failed with status code 400") {
          setErrorData("getPagesByFolderIdError");
        }
        isLoading(false);
        setCurrentAction(null);
      });
  };
  const getPagesByDocumentId = async (documentId, page, limit, action) => {
    setSelectedPage([]);
    dispatch({
      type: "GET_PAGES_BY_FOLDER_ID",
      payload: {
        pages: [],
        pagesPagination: {},
      },
    });
    isLoading(true);
    setCurrentAction(action);
    if (limit === 1) {
      setImageCurrentAction("getSingleImage");
    }
    const params = {
      page: page || 1,
      limit: limit || 25,
    };
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/document/${documentId}/image`, {
        params,
      })
      .then(async (response) => {
        const results = response.data;
        if (limit === 1) {
          dispatch({
            type: "GET_SINGLE_PAGE_BY_FOLDER_ID",
            payload: {
              listPages: results,
              listPagesPagination: results.meta,
            },
          });
        } else {
          if (results.data.errorMessage === "Lambda timeout.") {
            localStorage.setItem("pagesPagination", JSON.stringify({}));
          } else {
            localStorage.setItem(
              "pagesPagination",
              JSON.stringify(results.meta),
            );
          }
          dispatch({
            type: "GET_PAGES_BY_FOLDER_ID",
            payload: {
              pages: results.data,
              pagesPagination: results.meta,
            },
          });
        }
        if (limit === 1) {
          localStorage.setItem(
            "listPagesPagination",
            JSON.stringify(results.meta),
          );
          getSingleImageByDocumentIdAndImageId(
            documentId,
            response.data.data[0].id,
          );
        }
        localStorage.setItem(
          "cardsNb",
          JSON.stringify(
            results.meta.totalCount - results.meta.currentPage * 25 > 25
              ? 25
              : results.meta.totalCount - results.meta.currentPage * 25 < 1
                ? 25
                : results.meta.totalCount - results.meta.currentPage * 25,
          ),
        );
        isLoading(false);
        setCurrentAction(null);
      })

      .catch(async (err) => {
        if (err.message !== "Request failed with status code 400") {
          setErrorData("getPagesByFolderIdError");
        }
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const getSinglePageByFolderId = async (
    folderId,
    documentId,
    page,
    limit,
    action,
  ) => {
    setErrorData(null);
    isLoading(true);
    setImageCurrentAction(action);
    const params = {
      page: page || 1,
      limit: limit || 1,
    };
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/${documentId}/image`, {
        params,
      })
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "GET_SINGLE_PAGE_BY_FOLDER_ID",
          payload: {
            listPages: results,
            listPagesPagination: results.meta,
          },
        });
        isLoading(false);
        localStorage.setItem(
          "listPagesPagination",
          JSON.stringify(results.meta),
        );
      })
      .catch(async (err) => {
        setErrorData("getSinglePageByFolderIdError");
        isLoading(false);
      });
  };

  const createNewFolder = async (name, id, lineType) => {
    isLoading(true);
    setCurrentAction("createNewFolder");
    await axios
      .post(`${config.REACT_APP_BASE_URL}/folder`, {
        name: name,
        type: id,
        lineType: lineType,
      })
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "GET_NEW_FOLDER",
          payload: {
            results,
          },
        });
        getSnackbar("successCreateFolder", "success");
        isLoading(false);
        setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorCreateFolder", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const uploadPart = async (
    folderId,
    url,
    partNumber,
    file,
    chunkSize,
    progressCount,
    retryCount = 0,
    maxRetries = 3,
  ) => {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    const CancelToken = axios.CancelToken;
    const source = CancelToken?.source();

    let timeoutId;
    const cancelRequestAfterThreeMinutes = () => {
      source.cancel("Request timeout");
    };
    try {
      let chunkFile = file.slice(
        (partNumber - 1) * chunkSize,
        partNumber * chunkSize,
      );
      let i = 0;
      let response = await instance.put(url, chunkFile, {
        headers: {
          contentType: file.type,
        },
        onUploadProgress: function (progressEvent) {
          i++;
          progressCount(partNumber, progressEvent.loaded, chunkFile.size);
          timeoutId = setTimeout(
            cancelRequestAfterThreeMinutes,
            config.REACT_APP_UPLOAD_TIMEOUT * 60 * 1000,
          ); // 15 minutes
          return; // Exit the callback without throwing an error
        },
        cancelToken: source.token,
      });
      setUploadedFolderId(folderId);
      startPreparePdf(folderId);
      isLoading(false);
      setUploadAction(`prepareFolder ${folderId}`);
      // setCanShowDialogLeavingPage(false);
      const ETag = response.headers["etag"];
      return { PartNumber: partNumber, ETag };
    } catch (error) {
      if (retryCount < maxRetries) {
        await delay(3 * 1000);
        return uploadPart(
          folderId,
          url,
          partNumber,
          file,
          chunkSize,
          progressCount,
          retryCount + 1,
          maxRetries,
        );
      }
      clearTimeout(timeoutId);
    }
  };

  const uploadFile = async (file, folderId, face, all, from, to) => {
    isLoading(true);
    setUploadAction(`uploadFile ${folderId}`);
    setCanShowDialogLeavingPageOnUpload([
      ...state?.canShowDialogLeavingPageOnUpload,
      folderId,
    ]);
    const chunkSize = 1024 * 1024 * 5;
    const fileSize = file.size;
    const parts = Math.ceil(fileSize / chunkSize);
    let totalUploadedBytes = 0; // Initialize totalUploadedBytes to 0
    const loadedBytesPerPart = new Array(parts).fill(0);

    const updateChunkProgress = (partNumber, loadedBytes, chunkSize) => {
      loadedBytesPerPart[partNumber - 1] = loadedBytes; // Update loaded bytes for the corresponding part
      const totalLoadedBytes = loadedBytesPerPart.reduce(
        (sum, bytes) => sum + bytes,
        0,
      );
      const partProgress = (totalLoadedBytes / fileSize) * 100; // Calculate part progress percentage
      if (folderId)
        setUploadingFolders({
          folderId,
          percentage: Math.round(partProgress.toFixed(2)),
        });
      setProgressCount(Math.round(partProgress.toFixed(2)));
    };
    await axios
      .post(`${config.REACT_APP_BASE_URL}/folder/${folderId}/upload`, {
        ext: file?.type.split("/")[1],
        isTwoFaces: face === "oneface" ? false : true,
        from: all ? 1 : from,
        to: all ? null : to,
        parts: parts,
      })
      .then(async (res) => {
        const uploadId = res.data.data.uploadId;
        const fileKey = res.data.data.fileKey;
        const presignedUrls = res.data.data.parts;

        const uploadPromises = presignedUrls.map((part, index) =>
          uploadPart(
            folderId,
            part.signedUrl,
            index + 1,
            file,
            chunkSize,
            updateChunkProgress,
          ),
        );
        const results = await Promise.all(uploadPromises).catch(async (err) => {
          deleteFolder(folderId, "deleteAfterUpload");
          isLoading(false);
          setUploadAction(null);
          getSnackbar("errorUploadFile", "error");
          setUploadedFolderId(null);
          setCanShowDialogLeavingPageOnUpload(
            state?.canShowDialogLeavingPageOnUpload.filter(
              (elt) => elt !== folderId,
            ),
          );
        });
        await axios
          .post(
            `${config.REACT_APP_BASE_URL}/folder/${folderId}/upload/finalize`,
            {
              key: fileKey,
              uploadId: uploadId,
              parts: results,
            },
          )
          .then(async (response) => {
            setUploadedFolderId(folderId);
            startPreparePdf(folderId);
            isLoading(false);
            setUploadAction(`prepareFolder ${folderId}`);
            setCanShowDialogLeavingPageOnUpload(
              state?.canShowDialogLeavingPageOnUpload.filter(
                (elt) => elt !== folderId,
              ),
            );
            setProgressCount(0);
            setUploadingFolders(
              Object.keys(state?.uploadingFolders).filter(
                (key) => key !== folderId,
              ),
            );
            getFolders(1, 25, "", "afterUpload");
          });
      })
      .catch(async (err) => {
        deleteFolder(folderId, "deleteAfterUpload");
        isLoading(false);
        setUploadAction(null);
        getSnackbar("errorUploadFile", "error");
        setFolderId(null);
      });
  };

  const startPreparePdf = (id) => {
    dispatch({
      type: "START_PREPARE_PDF",
      payload: id,
    });
  };
  const getLineTypes = async (id) => {
    setErrorData(null);
    isLoading(true);
    setCurrentAction("getLineTypes");
    await axios
      .get(`${config.REACT_APP_BASE_URL}/script-names?docTypeId=${id}`)
      .then((response) => {
        const results = response.data;
        dispatch({
          type: "GET_LINE_TYPES",
          payload: {
            results,
          },
        });
        isLoading(false);
        setCurrentAction(null);
      })
      .catch((err) => {
        setErrorData("getscriptsError");
        isLoading(false);
        setCurrentAction(null);
      });
  };
  const isLoading = (loading) => {
    dispatch({
      type: "SET_LOADER",
      payload: loading,
    });
  };
  const setTashkeelStatus = (status) => {
    dispatch({
      type: "SET_TASHKEEL_STATUS",
      payload: status,
    });
  };
  const setTashkeelLoadings = (id) => {
    dispatch({
      type: "SET_TASHKEEL_LOADINGS",
      payload: id,
    });
  };
  const removeTashkeelLoadings = (id) => {
    dispatch({
      type: "REMOVE_TASHKEEL_LOADINGS",
      payload: id,
    });
  };
  const getSingleImage = async (folderId, imageId, action) => {
    const setItemInPlace = (data) => {
      state.image.data = {
        ...state.image.data,
        regions: data?.data?.regions,
        predictions: data?.data?.predictions,
        predictionsStatus: data?.data?.predictionsStatus,
        predictionAlert: data?.data?.predictionAlert,
      };
    };

    isLoading(true);
    setErrorData(null);
    await axios
      .get(`${config.REACT_APP_BASE_URL}/folder/${folderId}/image/${imageId}`)
      .then(async (response) => {
        const results = response.data;

        if (action === "afterPrediction") {
          setItemInPlace(results);
          startConvertPages(false);
        } else {
          dispatch({
            type: "GET_SINGLE_IMAGE",
            payload: {
              image: results,
            },
          });
        }

        isLoading(false);
        setImageCurrentAction(null);
        getLines(
          folderId,
          response?.data?.data?.documentId,
          response?.data?.data?.id,
          action,
        );
      })
      .catch(async (err) => {
        setErrorData("getSingleImageError");
        const data = {};
        dispatch({
          type: "GET_SINGLE_IMAGE",
          payload: {
            image: data,
          },
        });
        isLoading(false);
        setImageCurrentAction(null);
      });
  };
  const getSingleImageByDocumentIdAndImageId = async (
    documentId,
    imageId,
    action,
  ) => {
    isLoading(true);
    setErrorData(null);
    if (action === "afterPublishOrHidePageOnSebr") {
      setImageCurrentAction("afterPublishOrHidePageOnSebr");
    } else {
      setImageCurrentAction("getSingleImage");
    }
    await axios
      .get(
        `${config.REACT_APP_BASE_URL}/folder/document/${documentId}/image/${imageId}`,
      )
      .then(async (response) => {
        const results = response.data;

        const imageIndexForUrl = response.data.data.id
          .substring(response.data.data.id.indexOf("-"))
          .substring(1);
        setImageDimensions(response.data.data.size);
        dispatch({
          type: "GET_SINGLE_IMAGE",
          payload: {
            image: results,
          },
        });

        if (state.pageView !== "document-view") {
          if (window.location.href.includes("app=sebr")) {
            window.history.replaceState(
              null,
              `${window.location.href.split("/pages/")[1]}`,
              `${imageIndexForUrl}?app=sebr`,
            );
          } else {
            window.history.replaceState(
              null,
              `${window.location.href.split("/pages/")[1]}`,
              `${imageIndexForUrl}`,
            );
          }
        }

        isLoading(false);
        setImageCurrentAction(null);
        getLines(
          response?.data?.data?.folderId,
          response?.data?.data?.documentId,
          response?.data?.data?.id,
          "getLines",
        );
        if (action === "afterPrediction") {
          getSnackbar("successTranscriptionPages", "success");
        }
      })
      .catch(async (err) => {
        if (err.response.data.message === "No image found with that id!") {
          setErrorData("imageNotFoundWithThatId");
        } else {
          setErrorData("getSingleImageError");
        }
        const data = {};
        dispatch({
          type: "GET_SINGLE_IMAGE",
          payload: {
            image: data,
          },
        });
        isLoading(false);
        setImageCurrentAction(null);
      });
  };

  const updateSingleImage = async (folderId, imageId) => {
    const setItemInPlace = (data) => {
      const results = state.pages.map((elt) =>
        elt._id === imageId ? data : { ...elt },
      );
      dispatch({
        type: "UPDATE_SINGLE_IMAGE",
        payload: {
          results,
        },
      });
    };

    const response = await axios.get(
      `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/${imageId}`,
    );
    const results = response.data;
    if (
      results.data.predictionsStatus === "In progress" ||
      results.data.predictionsStatus === "Re-predicting"
    ) {
      startConvertPages(true);
    }
    if (!state.image.predictions && results.data.predictions) {
      dispatch({
        type: "GET_SINGLE_IMAGE",
        payload: {
          image: results,
        },
      });
      getLines(
        folderId,
        response?.data?.data?.documentId,
        response?.data?.data?.id,
        "getLines",
      );
      getUserInfo();
      startConvertPages(false);
    }

    setItemInPlace(results.data);

    return results;
  };

  const setImageIds = (ids) => {
    dispatch({
      type: "SET_IMAGES_IDS_TRANSCRIPTION_IN_PROGRESS",
      payload: ids,
    });
  };
  const removeImagesIdsTranscriptionDone = (ids) => {
    dispatch({
      type: "REMOVE_IMAGES_IDS_TRANSCRIPTION_DONE",
      payload: ids,
    });
  };
  const setSelectedPage = (array) => {
    dispatch({
      type: "SET_IMAGES_IDS_TO_TRANSCRIBE",
      payload: array,
    });
  };

  const convertImages = async (
    folderId,
    ids,
    documentId,
    documentType,
    customModel,
    view,
    imageId,
  ) => {
    dispatch({
      type: "SET_LINES",
      payload: {
        lines: undefined,
      },
    });
    resetFailedReceivedObjects();
    if (!user.data.onboardingData.predictionOnboarding.isChecked) {
      setOnboardingData("prediction", documentId, true);
    }
    let pagesToTranscribe = ids.filter((element2) => {
      const matchingElement = state.pages
        ?.filter(
          (elt) =>
            (elt.predictionsStatus === "Done" ||
              elt.predictionsStatus === "Retranscribed") &&
            elt.allowedPredictions === 0,
        )
        .find((element1) => element1?._id === element2.imageID);
      return !state.pages?.includes(matchingElement);
    });

    setImageIds(pagesToTranscribe);
    isLoading(true);
    setCurrentAction("convertPages");
    startConvertPages(true);
    await axios
      .post(`${config.REACT_APP_BASE_URL}/folder/${folderId}/image/line`, {
        documentType: documentType,
        customModel: customModel,
        images: ids,
      })
      .then(async (response) => {
        const results = response.data;
        if (response.status === 202) {
          const errorLength = results.ERROR.length;
          errorLength > 1
            ? getSnackbar("manyTranscriptionInProgress", "info")
            : getSnackbar("transcriptionInProgress", "info");
        }
        getUserInfo();
        dispatch({
          type: "CONVERT_IMAGES",
          payload: {
            results,
          },
        });
      })

      .catch(async (err) => {
        setCurrentAction(null);
        startConvertPages(false);
        removeImagesIdsTranscriptionDone([]);
        if (err.toString().includes("400")) {
          getSnackbar(
            "errorTranscriptionAlreadyTranscribed",
            "error",
            "clickOnImageToViewText",
          );
        } else {
          getSnackbar("errorTranscription", "error");
        }
        if (view !== "document-view") {
          getLines(folderId, documentId, imageId, "getLines");
        }
      });
  };
  const setImage = (el) => {
    dispatch({
      type: "SET_IMAGE",
      payload: el,
    });
  };
  const getOffers = async () => {
    isLoading(true);
    setCurrentAction("getOffers");
    setErrorData(null);
    await axios
      .get(`${config.REACT_APP_BASE_URL}/admin/offer`)
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "GET_OFFERS",
          payload: {
            results,
          },
        });
      })
      .catch(async (error) => {
        isLoading(false);
        setCurrentAction(null);
        setErrorData("getOffersError");
      });
  };
  const updateLines = async (folderId, documentId, imageId, newTextObj) => {
    isLoading(true);
    setCurrentAction("updateLines");
    const setItemInPlace = (data) => {
      const results = state.lines.map((elt) =>
        elt._id === newTextObj[0].lineID ? data : elt,
      );

      dispatch({
        type: "UPDATE_LINES",
        payload: results,
      });
    };

    await axios
      .put(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/document/${documentId}/image/${imageId}/line`,
        newTextObj,
      )
      .then(async (response) => {
        const results = response.data;
        getSnackbar("successUpdateLines", "success");
        isLoading(false);
        setCurrentAction(null);
        setItemInPlace(results.updatedline);
      })
      .catch(async (err) => {
        isLoading(false);
        setCurrentAction(null);
      });
  };
  const setCurrentAction = (currentAction) => {
    dispatch({
      type: "SET_CURRENT_ACTION",
      payload: currentAction,
    });
  };
  const setUploadAction = (action) => {
    dispatch({
      type: "SET_UPLOAD_ACTION",
      payload: action,
    });
  };

  const markImageAsDone = async (folderId, image_id, documentId, imageId) => {
    isLoading(true);
    setCurrentAction("markAsDone");
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/${image_id}/donepage`,
      )
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "MARK_IMAGE_AS_DONE",
          payload: {
            results,
          },
        });
        await updateFolder(folderId);
        await getSingleImageByDocumentIdAndImageId(
          documentId,
          imageId,
          "getImageAfterMarkImageAsDone",
        );
        await getSnackbar("successMarkImageAsDone", "success");
        await isLoading(false);
        await setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorMarkImageAsDone", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const setRetrain = async (folderId, documentId) => {
    if (!user.data.onboardingData.retrainOnboarding.isChecked) {
      setOnboardingData("retrain", documentId, true);
    }
    if (!user.data.onboardingData.madOnboarding.isChecked) {
      setOnboardingData("markAsDone", documentId, true);
    }
    isLoading(true);
    setCurrentAction("retrain");
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/document/${documentId}/retrain`,
      )
      .then(async (response) => {
        dispatch({
          type: "SET_RETRAIN",
          payload: "inProgress",
        });
        await updateFolder(folderId);
        isLoading(false);
        setCurrentAction(null);
        getSnackbar(
          "retrainInProgress",
          "success",
          "retrainInProgressDescription",
        );
      })
      .catch(async (err) => {
        getSnackbar("errorSetRetrain", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const setRetrainStatus = (str) => {
    dispatch({
      type: "SET_RETRAIN",
      payload: str,
    });
  };
  const setFolderNotif = async (folderId, notifType) => {
    isLoading(true);
    await axios
      .post(`${config.REACT_APP_BASE_URL}/folder/${folderId}/notification`, {
        notifType: notifType,
        notifStatus: null,
      })
      .then(async (response) => {
        await updateFolder(folderId);
        isLoading(false);
        setCurrentAction(null);
      })
      .catch(async (err) => {
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const deleteImages = async (folderId, ids, el) => {
    isLoading(true);
    setCurrentAction("deleteImages");
    await axios
      .delete(`${config.REACT_APP_BASE_URL}/folder/${folderId}/image`, {
        data: ids,
      })
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "DELETE_IMAGES",
          payload: {
            results,
          },
        });
        getSnackbar("successDeleteImages", "success");
        isLoading(false);
        setCurrentAction(null);
        if (ids.length === el.imagesCount) {
          navigate(getNavigationParams(`/workspace`));
          deleteFolder(el._id);
        }
      })
      .catch(async (err) => {
        getSnackbar("errorDeleteImages", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const setFolderId = (folderId) => {
    dispatch({
      type: "SET_FOLDER_ID",
      payload: folderId,
    });
    localStorage.setItem("folderId", JSON.stringify(folderId));
  };

  const setUploadedFolderId = (folderId) => {
    dispatch({
      type: "SET_UPLOADED_FOLDER_ID",
      payload: folderId,
    });
    localStorage.setItem("uploadedFolderId", JSON.stringify(folderId));
  };

  const setPage = (page) => {
    dispatch({
      type: "SET_PAGE",
      payload: page,
    });
    localStorage.setItem("pageId", JSON.stringify(page));
  };
  const setIndexPage = (index) => {
    dispatch({
      type: "SET_INDEX_PAGE",
      payload: index,
    });
    localStorage.setItem("index", JSON.stringify(index));
  };
  const setFolderAction = (folderAction) => {
    dispatch({
      type: "SET_FOLDER_ACTION",
      payload: folderAction,
    });
  };
  const setImageCurrentAction = (imageAction) => {
    dispatch({
      type: "SET_IMAGE_ACTION",
      payload: imageAction,
    });
  };
  const setCurrentFolder = (currentFolder) => {
    dispatch({
      type: "SET_NUMBER_PAGES",
      payload: currentFolder,
    });
    localStorage.setItem("currentFolder", JSON.stringify(currentFolder));
  };
  const setProgressCount = (currentProgress) => {
    dispatch({
      type: "SET_PROGRESS_COUNT",
      payload: currentProgress,
    });
  };
  const exportText = async (folderId, ids) => {
    const element = document.createElement("a");
    isLoading(true);
    setCurrentAction("exportText");
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/download`,
        ids,
      )
      .then(async (response) => {
        const results = response.data;
        const file = new Blob([results], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = `${folderId}.txt`;
        document.body.appendChild(element);
        element.click();
        getSnackbar("successExport", "success");
        setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorExportText", "error");
        setCurrentAction(null);
      });
  };
  const exportDoc = async (folderId, ids) => {
    isLoading(true);
    setCurrentAction("exportDoc");
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/download`,
        ids,
      )
      .then(async (response) => {
        const results = response.data;
        const paragraphs = results.split("\n\n\n");

        var doc = new Document({ sections: [] });
        paragraphs.map((item) => {
          let paragraph = new Paragraph({ bidirectional: true });
          let lines = item.split("\n");
          let pageRef = lines.shift();

          lines.reverse().map((line) => {
            const newLine = new TextRun({
              text: line,
              font: "Traditional Arabic",
              size: 32,
              rightToLeft: true,
              break: 1,
            });
            paragraph.addRunToFront(newLine);
          });
          paragraph.addRunToFront(
            new TextRun({
              text: pageRef,
              font: "Traditional Arabic",
              size: 32,
              bold: true,
              rightToLeft: true,
              break: 1,
            }),
          );
          doc.addSection({
            children: [paragraph],
          });
        });

        Packer.toBlob(doc).then((blob) => {
          saveAs(blob, `${folderId}.docx`);
        });
        getSnackbar("successExport", "success");
        setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorExportDoc", "error");
        setCurrentAction(null);
      });
  };

  const exportFile = async (folderId, fileType, ids) => {
    isLoading(true);
    setCurrentAction("exportPdf");
    const data = {
      fileType,
      imageIds: ids,
    };
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/download`,
        data,
      )
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "EXPORT_FILE",
          payload: {
            results,
          },
        });
        setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorExportPdf", "error");
        setCurrentAction(null);
        startExportPdf(false);
      });
  };

  const exportPDF = async (folderId, documentID) => {
    isLoading(true);
    setCurrentAction("exportPdf");
    await axios
      .get(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/document/${documentID}/export`,
      )
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "EXPORT_PDF",
          payload: {
            results,
          },
        });
        setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorExportPdf", "error");
        setCurrentAction(null);
        startExportPdf(false);
      });
  };

  const setloadingData = (loadingData) => {
    dispatch({
      type: "SET_LOADING_DATA",
      payload: loadingData,
    });
  };

  const setSocketData = (data) => {
    dispatch({
      type: "SOCKET_CONNECTION_DATA",
      payload: data,
    });
  };

  const setError = (error, errorMsg) => {
    const err = { error, errorMsg };
    dispatch({
      type: "SET_ERROR",
      payload: err,
    });
  };

  const addLine = async (folderId, imageId, index, documentId) => {
    isLoading(true);
    setCurrentAction("addLine");
    await axios
      .post(`${config.REACT_APP_BASE_URL}/folder/${folderId}/image/addline`, [
        {
          imageID: imageId,
          index: index,
        },
      ])
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "ADD_NEW_LINE",
          payload: {
            results,
          },
        });
        getSnackbar("successAddLine", "success");
        isLoading(false);
        setCurrentAction(null);
        getLines(folderId, documentId, imageId, "getLines");
      })
      .catch(async (err) => {
        getSnackbar("errorAddLine", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const deleteLine = async (folderId, documentId, imageId, lineId) => {
    isLoading(true);
    setCurrentAction("deleteLine");
    await axios
      .delete(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/document/${documentId}/image/${imageId}/line`,
        {
          data: [
            {
              lineID: lineId,
            },
          ],
        },
      )
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "DELETE_LINE",
          payload: {
            results,
          },
        });
        getSnackbar("successDeleteLine", "success");
        isLoading(false);
        setCurrentAction(null);
        getLines(folderId, documentId, imageId, "getLines");
      })
      .catch(async (err) => {
        getSnackbar("errorDeleteLine", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const disconnectFromStomp = async () => {
    await axios
      .post(config.REACT_APP_BASE_URL + "/user/disconnect", {
        sessionId: localStorage.getItem("stompQueue"),
      })
      .catch((err) => {
        getSnackbar(err.message, "error");
      });
    stompClientService.unsubscribe(
      `/queue/${localStorage.getItem("stompQueue")}`,
    );
    localStorage.removeItem("stompQueue");
  };

  useUnload();

  const getLines = async (
    folderId,
    documentId,
    imageId,
    currentAction,
    image_id,
    openHistoryDialog,
  ) => {
    setErrorData(null);
    isLoading(true);
    setCurrentAction(currentAction);

    await axios
      .get(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/document/${documentId}/image/${imageId}/line`,
      )
      .then((response) => {
        setCurrentAction(null);
        if (currentAction === "logHistory") {
          openHistoryDialog(image_id);
        }
        const lines = response.data.data.map((line, index) => {
          return {
            text: line.text || "",
            id: line._id,
            polygon: line.polygon,
            index: line.index,
            order: index,
            imageID: line.imageID,
            baseline: line.baseline,
          };
        });
        const editedLines = JSON.parse(localStorage.getItem("editedLines"));
        if (
          currentAction === "getLinesAfterTashkeel" ||
          state.mode === "editSegmentation" ||
          !lines.length
        ) {
          setLines(lines);
        } else {
          setLines(
            editedLines?.length && lines[0].imageID === editedLines[0].imageID
              ? editedLines
              : lines,
          );
        }

        if (currentAction === "getLinesAfterTashkeel") {
          setTashkeelStatus(TASHKEEL_STATUS.DONE);
          removeTashkeelLoadings(imageId);
        }
      })
      .catch(async (err) => {
        setTashkeelStatus(TASHKEEL_STATUS.DONE);
        removeTashkeelLoadings(imageId);
        setErrorData("getLinesError");
        isLoading(false);
        setCurrentAction(null);
      })
      .finally(() => {
        setTashkeelStatus(TASHKEEL_STATUS.DONE);
        if (currentAction === "getLinesAfterTashkeel") {
          removeTashkeelLoadings(imageId);
        }
        isLoading(false);
      });
  };

  const setMode = (str) => {
    dispatch({
      type: "SET_MODE",
      payload: str,
    });
  };

  const updateImageNotif = async (folderId, imageId, notifType) => {
    isLoading(true);
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/${imageId}/notification`,
        {
          notifType: notifType,
        },
      )
      .then(async (response) => {
        dispatch({
          type: "GET_SINGLE_IMAGE",
          payload: {
            image: response?.data,
          },
        });
        isLoading(false);
        setCurrentAction(null);
      })
      .catch(async (err) => {
        getSnackbar("errorUpdateImageNotif", "error");
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const startConvertPages = (booleen) => {
    dispatch({
      type: "START_CONVERT_PAGES",
      payload: booleen,
    });
  };
  const startExportPdf = (booleen) => {
    dispatch({
      type: "START_EXPORT_PDF",
      payload: booleen,
    });
  };
  const setErrorData = (str) => {
    dispatch({
      type: "SET_ERROR_DATA",
      payload: str,
    });
  };
  const goToWorkspace = (booleen) => {
    dispatch({
      type: "GO_TO_WORKSPACE",
      payload: booleen,
    });
  };
  const setNewText = (txtData) => {
    dispatch({
      type: "SET_NEW_TXT",
      payload: txtData,
    });
  };
  const setFoldersIds = (ids) => {
    dispatch({
      type: "SET_FOLDERS_IDS",
      payload: ids,
    });
    localStorage.setItem("foldersIds", JSON.stringify(ids));
  };

  const updateText = async (
    folderId,
    lines,
    imageId,
    documentId,
    image_id,
    action,
    onClose,
    page,
    publishPage,
  ) => {
    isLoading(true);
    setCurrentAction("updateText");

    const linesToUpdate = [];
    for (let i = 0; i < lines.length; i++) {
      const { id, text, index } = lines[i];
      const polygon = lines[i]?.polygon ? lines[i]?.polygon : [];

      linesToUpdate.push({
        imageID: imageId,
        index: index,
        order: i,
        text: text ? text : " ",
        polygon: polygon ? polygon : [],
      });
    }

    LineApi.updateText(folderId, linesToUpdate)
      .then(async (response) => {
        if (action === "restoreVersion") {
          await getLines(folderId, documentId, imageId, "getLines");
        }
        if (action === "saveBeforeSwitchToEditSegmentation") {
          await getLines(folderId, documentId, imageId, action);
        }
        isLoading(false);
        setCurrentAction(null);
        if (action === "updatebeforeMarAsDone") {
          markImageAsDone(folderId, image_id, documentId, imageId);
          onClose();
        }
        if (action === "updatebeforePublishPage") {
          publishPage();
        }
      })
      .catch(async (err) => {
        setCanShowDialogLeavingPage(true);
        getSnackbar("errorUpdateLines", "error");
        isLoading(false);
        setCurrentAction(null);
      })
      .finally(() => {
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const tashkeelTexts = async (strategy, ...args) => {
    const [folderId, imageId, documentId] = args;

    setCurrentAction("tashkeelTexts");
    setTashkeelStatus(TASHKEEL_STATUS.IN_PROGRESS);
    setTashkeelLoadings(imageId);
    isLoading(true);

    let texts = state.lines.map((line) => line.text);
    const linesToUpdate = [];
    for (let i = 0; i < state.lines.length; i++) {
      const { id, text, index } = state.lines[i];
      const polygon = state.lines[i]?.polygon ? state.lines[i]?.polygon : [];

      linesToUpdate.push({
        imageID: imageId,
        index: index,
        order: i,
        text: text,
        polygon: polygon ? polygon : [],
      });
    }
    LineApi.updateText(folderId, linesToUpdate).then(() => {
      setCanShowDialogLeavingPage(false);
      LineApi.tashkeelText({ texts, strategy })
        .then(async (response) => {
          const linesToWithTashkil = state.lines.map((line, index) => {
            return {
              ...line,
              text: response.data[index] || "",
            };
          });
          await LineApi.updateText(
            folderId,
            linesToUpdate.map((line, index) => {
              return { ...line, text: response.data[index] };
            }),
          ).then((res) => {
            isLoading(false);
            setCanShowDialogLeavingPage(false);
            localStorage.setItem(
              "editedLines",
              JSON.stringify(linesToWithTashkil),
            );
            let currentImage = parseUrl(document.URL)
              .path.split("/")
              .filter(
                (path) => !["editor", "document", "pages", ""].includes(path),
              )
              .join("-");
            if (currentImage === linesToWithTashkil[0].imageID) {
              getLines(folderId, documentId, imageId, "getLinesAfterTashkeel");
            }
          });
        })
        .catch(async (err) => {
          isLoading(false);
          setTashkeelStatus(TASHKEEL_STATUS.DONE);
          removeTashkeelLoadings(imageId);
          getSnackbar("vocalisationNotApplied", "error");
          setCurrentAction(null);
        });
    });
  };

  const setMousePos = (obj) => {
    dispatch({
      type: "SET_MOUSE_POS",
      payload: obj,
    });
    localStorage.setItem("mousePos", JSON.stringify(obj));
  };
  const storeAffiliationDetails = (details) => {
    dispatch({
      type: "SET_AFFILIATION_DETAILS",
      payload: details?.affiliationDetails ? details?.affiliationDetails : [],
    });
    dispatch({
      type: "SET_USER_COUNTRY",
      payload: details?.userCountry,
    });
    if (Object.keys(details).length !== 0) {
      details?.affiliationDetails &&
        localStorage.setItem(
          "details",
          JSON.stringify(details?.affiliationDetails),
        );
      details?.userCountry &&
        localStorage.setItem(
          "userCountry",
          JSON.stringify(details?.userCountry),
        );
    }
  };
  const setPaymentToken = (str) => {
    dispatch({
      type: "SET_PAYMENT_TOKEN",
      payload: str,
    });
    localStorage.setItem("paymentToken", JSON.stringify(str));
  };
  const setStripeUrl = (str) => {
    dispatch({
      type: "SET_STRIPE_URL",
      payload: str,
    });
    localStorage.setItem("stripeUrl", JSON.stringify(str));
  };
  const setStripeSessionId = (str) => {
    dispatch({
      type: "SET_STRIPE_SESSION_ID",
      payload: str,
    });
    localStorage.setItem("stripeSessionId", JSON.stringify(str));
  };
  const setFinalPrice = (finalPrice) => {
    dispatch({
      type: "SET_FINAL_PRICE",
      payload: finalPrice,
    });
    localStorage.setItem("finalPrice", JSON.stringify(finalPrice));
  };

  const saveLastText = (lastText) => {
    dispatch({
      type: "SAVE_LAST_TEXT",
      payload: lastText,
    });
    localStorage.setItem("lastText", JSON.stringify(lastText));
  };

  const setCanShowDialogLeavingPage = (booleen) => {
    dispatch({
      type: "SET_CAN_DIALOG_LEAVING_PAGE",
      payload: booleen,
    });
    localStorage.setItem("canShowDialogLeavingPage", JSON.stringify(booleen));
  };
  const setCanShowDialogLeavingPageOnUpload = (arr) => {
    dispatch({
      type: "SET_CAN_DIALOG_LEAVING_PAGE_ON_UPLOAD",
      payload: arr,
    });
    localStorage.setItem(
      "canShowDialogLeavingPageOnUpload",
      JSON.stringify(arr),
    );
  };
  const setImageDimensions = (dimensions) => {
    dispatch({
      type: "SET_IMAGE_DIMENSIONS",
      payload: dimensions,
    });
  };
  const setDisplayMode = (str) => {
    dispatch({
      type: "SET_DISPLAY_MODE",
      payload: str,
    });
    localStorage.setItem("displayMode", JSON.stringify(str));
  };

  const setLines = (lines) => {
    dispatch({
      type: "SET_LINES",
      payload: lines,
    });
    localStorage.setItem("editedLines", JSON.stringify(lines));
  };

  const handleSave = async (
    folderId,
    imageId,
    documentId,
    lines,
    image_id,
    action,
    onClose,
    page,
    publishPage,
  ) => {
    setCanShowDialogLeavingPage(false);
    await updateText(
      folderId,
      lines,
      imageId,
      documentId,
      image_id,
      action,
      onClose,
      page,
      publishPage,
    );
    detectFirstHandleChange(false);
    setReorderState(false);
    if (
      !user.data.onboardingData.madOnboarding.isChecked &&
      !user.data.onboardingData.editTextOnboarding.documentId.includes(
        documentId,
      )
    ) {
      setOnboardingData("editText", documentId, false);
    }
  };
  const saveNewText = async (
    folderId,
    documentId,
    imageID,
    updatedLine,
    action,
    editSegmentation,
  ) => {
    setCurrentAction(action);
    setCanShowDialogLeavingPage(false);
    await axios
      .put(
        `${config.REACT_APP_BASE_URL}/folder/${folderId}/image/updateText`,
        updatedLine,
      )
      .then(async (response) => {
        setCurrentAction(null);
      })
      .catch(async (err) => {
        setCanShowDialogLeavingPage(true);
        getSnackbar("errorUpdateLines", "error");
        setCurrentAction(null);
      });

    detectFirstHandleChange(false);
    setReorderState(false);
    if (
      !user.data.onboardingData.madOnboarding.isChecked &&
      !user.data.onboardingData.editTextOnboarding.documentId.includes(
        documentId,
      )
    ) {
      setOnboardingData("editText", documentId, false);
    }
  };
  const detectFirstHandleChange = (firstHandleChange) => {
    dispatch({
      type: "DETECT_FIRST_EDIT",
      payload: firstHandleChange,
    });
  };
  const setReorderState = (reorderState) => {
    dispatch({
      type: "SET_REORDER_STATE",
      payload: reorderState,
    });
  };
  const setReorderMode = (reorderMode) => {
    dispatch({
      type: "SET_REORDER_MODE",
      payload: reorderMode,
    });
  };
  const setPageView = (view) => {
    dispatch({
      type: "SET_PAGE_VIEW",
      payload: view,
    });
  };
  const setKonvaImage = (imgTag) => {
    dispatch({
      type: "SET_KONVA_IMAGE",
      payload: imgTag,
    });
  };

  const setUploadingFolders = (obj) => {
    dispatch({
      type: "SET_UPLOADING_FOLDERS",
      payload: obj,
    });
  };

  const removeUploadedFolder = (folderId) => {
    dispatch({
      type: "REMOVING_UPLOADED_FOLDER",
      payload: folderId,
    });
  };

  const setDetectClick = (booleen) => {
    dispatch({
      type: "SET_DETECT_CLICK",
      payload: booleen,
    });
  };

  useEffect(() => {
    const folderId = JSON.parse(localStorage.getItem("folderId"));
    const page = JSON.parse(localStorage.getItem("page"));
    const index = JSON.parse(localStorage.getItem("index"));
    const currentFolder = JSON.parse(localStorage.getItem("currentFolder"));
    const foldersPagination = JSON.parse(
      localStorage.getItem("foldersPagination"),
    );
    const pagesPagination = JSON.parse(localStorage.getItem("pagesPagination"));
    const listPagesPagination = JSON.parse(
      localStorage.getItem("listPagesPagination"),
    );
    const foldersIds = JSON.parse(localStorage.getItem("foldersIds")) || [];
    const details = JSON.parse(localStorage.getItem("details")) || [];
    const userCountry = JSON.parse(localStorage.getItem("userCountry")) || "";
    const paymentToken = JSON.parse(localStorage.getItem("paymentToken"));
    const stripeUrl = JSON.parse(localStorage.getItem("stripeUrl"));
    const stripeSessionId = JSON.parse(localStorage.getItem("stripeSessionId"));
    const finalPrice = JSON.parse(localStorage.getItem("finalPrice"));
    // const imageDimensions = JSON.parse(localStorage.getItem("imageDimensions"));
    const displayMode = JSON.parse(localStorage.getItem("displayMode"));
    const canShowDialogLeavingPage = JSON.parse(
      localStorage.getItem("canShowDialogLeavingPage"),
    );
    const canShowDialogLeavingPageOnUpload =
      JSON.parse(localStorage.getItem("canShowDialogLeavingPageOnUpload")) ||
      [];

    const editedLines = JSON.parse(localStorage.getItem("editedLines")) || [];
    dispatch({
      type: "SET_LINES",
      payload: editedLines,
    });

    dispatch({
      type: "SET_FOLDER_ID",
      payload: folderId,
    });
    dispatch({
      type: "SET_FOLDERS_IDS",
      payload: foldersIds,
    });
    dispatch({
      type: "SET_PAGE",
      payload: page,
    });
    if (!window.location.href.includes("app=sebr")) {
      dispatch({
        type: "SET_INDEX_PAGE",
        payload: index,
      });
    }
    dispatch({
      type: "SET_NUMBER_PAGES",
      payload: currentFolder,
    });
    dispatch({
      type: "GET_FOLDERS",
      payload: {
        foldersPagination: foldersPagination,
      },
    });
    if (!state?.pages?.data?.errorMessage) {
      dispatch({
        type: "GET_PAGES_BY_FOLDER_ID",
        payload: {
          pagesPagination: pagesPagination,
        },
      });
    }
    dispatch({
      type: "GET_SINGLE_PAGE_BY_FOLDER_ID",
      payload: {
        listPagesPagination: listPagesPagination,
      },
    });
    dispatch({
      type: "SET_PAYMENT_TOKEN",
      payload: paymentToken,
    });
    dispatch({
      type: "SET_STRIPE_URL",
      payload: stripeUrl,
    });
    dispatch({
      type: "SET_STRIPE_SESSION_ID",
      payload: stripeSessionId,
    });
    dispatch({
      type: "SET_AFFILIATION_DETAILS",
      payload: details,
    });
    dispatch({
      type: "SET_USER_COUNTRY",
      payload: userCountry,
    });
    dispatch({
      type: "SET_FINAL_PRICE",
      payload: finalPrice,
    });
    // dispatch({
    //   type: "SET_IMAGE_DIMENSIONS",
    //   payload: imageDimensions,
    // });
    if (displayMode) {
      dispatch({
        type: "SET_DISPLAY_MODE",
        payload: displayMode,
      });
    } else {
      dispatch({
        type: "SET_DISPLAY_MODE",
        payload: "horizontal",
      });
    }
    dispatch({
      type: "SET_CAN_DIALOG_LEAVING_PAGE",
      payload: canShowDialogLeavingPage,
    });
    dispatch({
      type: "SET_CAN_DIALOG_LEAVING_PAGE_ON_UPLOAD",
      payload: canShowDialogLeavingPageOnUpload,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <DataContext.Provider
      value={{
        ...state,
        getPrintsType,
        getFolders,
        getSingleFolder,
        deleteFolder,
        getSinglePageByFolderId,
        createNewFolder,
        uploadFile,
        getPagesByFolderId,
        getLineTypes,
        isLoading,
        getSingleImage,
        convertImages,
        setImage,
        getOffers,
        updateLines,
        setCurrentAction,
        markImageAsDone,
        deleteImages,
        setFolderId,
        setPage,
        setIndexPage,
        setFolderAction,
        setImageCurrentAction,
        setCurrentFolder,
        exportText,
        exportPDF,
        setloadingData,
        setSocketData,
        setError,
        updateSingleImage,
        addLine,
        deleteLine,
        getLines,
        startConvertPages,
        startExportPdf,
        setErrorData,
        goToWorkspace,
        setNewText,
        setImageIds,
        updateFolder,
        setRetrain,
        setRetrainStatus,
        setFolderNotif,
        exportDoc,
        startPreparePdf,
        setUploadAction,
        setFoldersIds,
        setUploadedFolderId,
        updateText,
        exportFile,
        setMousePos,
        storeAffiliationDetails,
        setPaymentToken,
        setStripeUrl,
        setStripeSessionId,
        setFinalPrice,
        saveLastText,
        setCanShowDialogLeavingPage,
        setCanShowDialogLeavingPageOnUpload,
        setImageDimensions,
        setDisplayMode,
        setLines,
        handleSave,
        detectFirstHandleChange,
        setReorderState,
        setReorderMode,
        getPagesByDocumentId,
        getFolderByDocumentId,
        getSingleImageByDocumentIdAndImageId,
        setProgressCount,
        setSelectedPage,
        updateImageNotif,
        setReceivedObjects,
        resetReceivedObjects,
        setFailedReceivedObjects,
        resetFailedReceivedObjects,
        disconnectFromStomp,
        removeImagesIdsTranscriptionDone,
        setUploadingFolders,
        removeUploadedFolder,
        setPageView,
        setKonvaImage,
        setMode,
        setDetectClick,
        tashkeelTexts,
        saveNewText,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;
