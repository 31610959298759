import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/styles/index.css";
import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import { SettingsProvider } from "./contexts/SettingsContext";
import { createBrowserHistory } from "history";
import TagManager from "react-gtm-module";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import "./utils/clarity";
import { StyledEngineProvider } from "@mui/material/styles";
import config from "./utils/settings";

const tagManagerArgs = {
  gtmId: config.REACT_APP_GTM,
  auth: config.REACT_APP_AUTH,
  preview: config.REACT_APP_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

const root = createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <StyledEngineProvider injectFirst>
    <HistoryRouter history={createBrowserHistory({ window })}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </HistoryRouter>
  </StyledEngineProvider>,
  //  </React.StrictMode>
);
