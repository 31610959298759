import React from "react";
import { IconButton, Typography, Grow } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiFire } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import { mdiWhiteBalanceIncandescent } from "@mdi/js";
import useData from "../../hooks/useData";

export const CustomTooltip = ({ open, page, action, onclose }) => {
  const { t } = useTranslation();
  const { folder, displayMode } = useData();

  const TooltipDescription = {
    retrain: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {`${t("youHave")} ${folder?.data?.countForRetrain} ${t(
          "pagesReady",
        )}, ${t("runNewConversion")}`}
      </Typography>
    ),
    successfullRetrain: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {`${t("newModelGenerated")} ${t("continueImprovingTheModel")}`}
      </Typography>
    ),
    blockedfullRetrain: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {t("youHaveReachedMaximumLimitForEnhancingModelsAccuracy")}
      </Typography>
    ),
    keepCurrentText: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {t("youCanRetranscript")}
      </Typography>
    ),
    prediction: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {t("forBetterResults")}
      </Typography>
    ),
    markAsDone: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {`${t("correct")} ${
          folder?.data?.nextSteps.length ? folder?.data?.nextSteps[0] : 8
        } ${t("zinkiWillUseThemAsReferenceToImproveAccuracy")}`}
      </Typography>
    ),
    videoTutorial: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {t("HelperTooltipAppTutorialVideo")}
      </Typography>
    ),
    uploadOnboarding: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {t("youMayReturnToRecommendationsByClickingHere")}
      </Typography>
    ),
    historyOnboarding: (
      <Typography variant="body2" color="var(--secondary-text-color)">
        {t("youCanRestoreOldVersion")}
      </Typography>
    ),
  };
  const TooltipIcon = {
    retrain: (
      <Icon path={mdiFire} size={1} className="custom-tooltip-retrain-icon" />
    ),
    successfullRetrain: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    blockedfullRetrain: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    keepCurrentText: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    markAsDone: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    videoTutorial: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    uploadOnboarding: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    historyOnboarding: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
    prediction: (
      <Icon
        path={mdiWhiteBalanceIncandescent}
        size={1}
        className="custom-tooltip-markAsDone-icon"
      />
    ),
  };

  return (
    <Grow in={true} {...{ timeout: 1000 }}>
      <div
        className={
          action === "videoTutorial"
            ? "custom-tooltip-videoTutorial"
            : action === "prediction"
              ? "custom-tooltip-prediction-container"
              : (action === "successfullRetrain" ||
                    action === "blockedfullRetrain") &&
                  page === "MOpage" &&
                  displayMode === "vertical"
                ? "custom-tooltip-container-retrain-vertical"
                : (action === "successfullRetrain" ||
                      action === "blockedfullRetrain") &&
                    page === "MOpage" &&
                    displayMode !== "vertical"
                  ? "custom-tooltip-container-retrain-horizontal"
                  : (action === "successfullRetrain" ||
                        action === "blockedfullRetrain") &&
                      page !== "MOpage"
                    ? "custom-tooltip-retrain"
                    : action === "keepCurrentText"
                      ? "custom-tooltip-container-keepCurrentText"
                      : action === "uploadOnboarding"
                        ? "custom-tooltip-container-uploadOnboarding-action"
                        : action === "historyOnboarding" &&
                            page === "MOpage" &&
                            displayMode === "vertical"
                          ? "custom-tooltip-container-historyOnboarding-action"
                          : action === "historyOnboarding" &&
                              page === "MOpage" &&
                              displayMode !== "vertical"
                            ? "custom-tooltip-container-historyOnboarding-action-horizontal"
                            : page === "MOpage" &&
                                (action === "markAsDone" ||
                                  displayMode === "vertical")
                              ? "custom-tooltip-container-markAsDone-action"
                              : "custom-tooltip-container"
        }
      >
        <div
          className={
            action === "videoTutorial"
              ? "custom-tooltip-content-videoTutorial"
              : action === "uploadOnboarding"
                ? "custom-tooltip-uploadOnboarding-content"
                : action === "prediction"
                  ? "custom-tooltip-prediction-content"
                  : action === "keepCurrentText"
                    ? "custom-tooltip-keepCurrentText-content"
                    : action === "historyOnboarding" && page === "MOpage"
                      ? "custom-tooltip-retrain-content"
                      : (action === "successfullRetrain" ||
                            action === "blockedfullRetrain") &&
                          page === "MOpage"
                        ? "custom-tooltip-retrain-content"
                        : (action === "successfullRetrain" ||
                              action === "blockedfullRetrain") &&
                            page !== "MOpage"
                          ? "custom-tooltip-content-retrain"
                          : page === "MOpage" &&
                              (action === "markAsDone" ||
                                displayMode === "vertical")
                            ? "custom-tooltip-markAsDone-content"
                            : "custom-tooltip-content"
          }
        >
          {TooltipIcon[action]}
          <div
            className={
              action === "videoTutorial"
                ? "custom-tooltip-description-and-close-icon-button-videoTutorial"
                : (action === "successfullRetrain" ||
                      action === "blockedfullRetrain") &&
                    page !== "MOpage"
                  ? "custom-tooltip-description-and-close-icon-button-successfullRetrain-outside"
                  : action === "successfullRetrain" ||
                      action === "blockedfullRetrain"
                    ? "custom-tooltip-description-and-close-icon-button-successfullRetrain"
                    : action === "uploadOnboarding"
                      ? "custom-tooltip-description-and-close-icon-button-uploadOnboarding"
                      : action === "keepCurrentText"
                        ? "custom-tooltip-description-and-close-icon-button-keepCurrentText"
                        : action === "prediction"
                          ? "custom-tooltip-description-and-close-icon-button-prediciton"
                          : action === "historyOnboarding" &&
                              displayMode !== "vertical"
                            ? "custom-tooltip-description-and-close-icon-button-historyOnboarding-horizontal"
                            : action === "historyOnboarding"
                              ? "custom-tooltip-description-and-close-icon-button-historyOnboarding"
                              : "custom-tooltip-description-and-close-icon-button"
            }
          >
            {TooltipDescription[action]}
            <IconButton
              className={
                action === "videoTutorial"
                  ? "custom-tooltip-icon-button-videoTutorial"
                  : action === "successfullRetrain" ||
                      action === "blockedfullRetrain"
                    ? "custom-tooltip-icon-button-successfullRetrain"
                    : action === "prediction"
                      ? "custom-tooltip-icon-button-successfullRetrain"
                      : action === "historyOnboarding"
                        ? "custom-tooltip-icon-button-successfullRetrain"
                        : action === "uploadOnboarding"
                          ? "custom-tooltip-icon-button-uploadOnboarding"
                          : "custom-tooltip-icon-button"
              }
              onClick={onclose}
            >
              <Icon path={mdiClose} size={1} className="custom-tooltip-icon" />
            </IconButton>
          </div>
        </div>
        {action !== "keepCurrentText" && (
          <div
            className={
              page === "MOpage" &&
              (action === "markAsDone" || displayMode === "vertical")
                ? "custom-tooltip-triangle-markAsDone"
                : "custom-tooltip-triangle-retrain"
            }
          ></div>
        )}
      </div>
    </Grow>
  );
};
