import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiHomeSearch } from "@mdi/js";
import "../../../assets/styles/layout.css";
import config from "../../../utils/settings";

const useStyles = makeStyles((theme) => ({
  searchPageButton: {
    display: theme.clientId === "zinki" && "none !important",
    marginInlineEnd: "32px !important",
  },
}));
const SearchPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      id="button-search-page"
      href={config.REACT_APP_SEBR_URL}
      color="primary"
      startIcon={<Icon path={mdiHomeSearch} size={1} />}
      className={classes.searchPageButton}
    >
      {t("searchPage")}
    </Button>
  );
};
export default SearchPage;
