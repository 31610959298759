import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import _kc from "../utils/Keycloak";
import SplashScreen from "../components/layout/SplashScreen";
import useSettings from "../hooks/useSettings";
import config from "../utils/settings";

const initialAuthState = {
  keycloak: null,
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  currentAction: null,
  fetchUserError: null,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    if (axios.defaults.headers.common)
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    if (axios.defaults.headers.common)
      delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "GET_USER_INFO": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "SET_CURRENT_ACTION": {
      return {
        ...state,
        currentAction: action.payload,
      };
    }
    case "SET_ERROR_DATA": {
      return {
        ...state,
        fetchUserError: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "Keycloack",
  logout: () => {},
  doLogout: () => Promise.resolve(),
  getToken: () => Promise.resolve(),
  createLoginUrl: () => Promise.resolve(),
  getUserInfo: () => Promise.resolve(),
  setCurrentAction: () => Promise.resolve(),
  setErrorData: () => Promise.resolve(),
  setOnboardingData: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    setSession(null);
  };

  _kc.onTokenExpired = () => {
    _kc.updateToken().then((refreshed) => {
      if (refreshed) {
        setSession(_kc.token);
      } else {
        _kc.logout();
      }
    });
    // .catch(() => {
    //   _kc.logout();
    // });
  };

  /* const sessionExpired = _kc.updateToken(5)
    .then(function (refreshed) {
      if (refreshed) {
        alert('Token was successfully refreshed');
      } else {
        alert('Token is still valid');
      }
    }).catch(function () {
      alert('Failed to refresh the token, or the session has expired');
      _kc.logout();
    }); */

  const getToken = () => _kc.token;

  const createLoginUrl = () => _kc.createLoginUrl;

  const setCurrentAction = (currentAction) => {
    dispatch({
      type: "SET_CURRENT_ACTION",
      payload: currentAction,
    });
  };

  const getUserInfo = async () => {
    setErrorData(null);
    setCurrentAction("getUserInfo");
    const params = {
      accessToken: _kc.token,
    };
    await axios
      .get(
        `${config.REACT_APP_BASE_URL}/user`,
        {
          params,
        },
        { Headers: `Bearer ${_kc.token}` },
      )
      .then(async (response) => {
        const results = response.data;
        dispatch({
          type: "GET_USER_INFO",
          payload: {
            user: results,
          },
        });
        setCurrentAction(null);
      })
      .catch(async (err) => {
        setErrorData("getUserInfoError");
        dispatch({
          type: "GET_USER_INFO",
          payload: {
            user: null,
          },
        });
        setCurrentAction(null);
      });
  };
  const setErrorData = (str) => {
    dispatch({
      type: "SET_ERROR_DATA",
      payload: str,
    });
  };

  const setOnboardingData = async (area, documentId, isChecked) => {
    isLoading(true);
    setCurrentAction("setOnboarding");
    await axios
      .put(`${config.REACT_APP_BASE_URL}/onboarding`, {
        area: area,
        documentId: documentId,
        isChecked: isChecked,
      })
      .then(async (response) => {
        isLoading(false);
        setCurrentAction(null);

        let updatedUserData;
        updatedUserData = state.user;
        state.user.data.onboardingData = response.data.updatedOnboardingData;
        getUserInfo();
      })
      .catch(async (err) => {
        isLoading(false);
        setCurrentAction(null);
      });
  };

  const { saveSettings } = useSettings();

  useEffect(() => {
    const initKeycloak = async () => {
      _kc
        .init({
          onLoad: "login-required",
          silentCheckSsoRedirectUri:
            window.location.origin + "/silent-check-sso.html",
          pkceMethod: "S256",
          // redirectUri: window.location.origin,
        })
        .then(async (authenticated) => {
          setSession(_kc.token);
          const params = {
            accessToken: _kc.token,
          };
          const response = await axios.get(
            `${config.REACT_APP_BASE_URL}/user`,
            {
              params,
            },
            { Headers: `Bearer ${_kc.token}` },
          );
          const updateSettings = JSON.parse(
            localStorage.getItem("updateSettings"),
          );
          if (!updateSettings) {
            saveSettings({
              direction:
                response?.data?.data?.locale === "ar" ||
                (navigator.language === "ar" && !response?.data?.data?.locale)
                  ? "rtl"
                  : "ltr",
              language:
                response?.data?.data?.locale === "ar" ||
                (navigator.language === "ar" && !response?.data?.data?.locale)
                  ? "ar"
                  : "en",
            });
          }
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user: response.data,
            },
          });
          if (!authenticated) {
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
          window.dataLayer.push({
            userId: response.data.data.sub,
          });
        })
        .catch((err) => {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        });
    };

    initKeycloak();
    // eslint-disable-next-line
  }, []);

  const doLogout = _kc.logout;
  const isLoading = (loading) => {
    dispatch({
      type: "SET_LOADER",
      payload: loading,
    });
  };

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Keycloack",
        logout,
        isLoading,
        doLogout,
        getToken,
        createLoginUrl,
        getUserInfo,
        setCurrentAction,
        setErrorData,
        setOnboardingData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
