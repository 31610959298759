import axios from "axios";

import ENDPOINTS from "./endpoints";

const LineApi = {
  async updateText(folderId, linesToUpdate) {
    try {
      const url = ENDPOINTS.UPDATE_TEXT(folderId);
      const response = await axios.put(url, linesToUpdate);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async tashkeelText({ texts, strategy }) {
    try {
      const url = ENDPOINTS.TASHKEEL_TEXT();
      const response = await axios.post(url, { texts, strategy });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default LineApi;
