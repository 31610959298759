export function getNavigationParams(url) {
  if (
    window.location.href.includes("app=sebr") ||
    window.location.href.includes("from=portal")
  ) {
    var hasOtherParams = false;
    if (window.location.href.includes("app=sebr")) {
      url = url.concat("?app=sebr");
      hasOtherParams = true;
    }
    if (window.location.href.includes("from=portal")) {
      if (hasOtherParams) {
        url = url.concat("&from=portal");
      } else {
        url = url.concat("?from=portal");
      }
    }
  }
  return url;
}
