import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/Footer";
import useAuth from "../../hooks/useAuth";
import Container from "@mui/material/Container";
import i18n from "i18next";
import useSettings from "../../hooks/useSettings";
import useData from "../../hooks/useData";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import OnboardingDialog from "../../components/dialogs/Onboarding";
import { Helmet } from "react-helmet";
import "../../assets/styles/layout.css";

const Layout = ({ children, page }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { user, setOnboardingData } = useAuth();
  const { settings } = useSettings();
  const {
    socketData,
    startExport,
    startExportPdf,
    setSocketData,
    folder,
    resetReceivedObjects,
    resetFailedReceivedObjects,
  } = useData();

  useEffect(() => {
    if (settings.language) {
      i18n.changeLanguage(settings.language);
    } else {
      i18n.changeLanguage(user?.data?.locale);
    }
    // eslint-disable-next-line
  }, [settings, user]);
  const [openOnboardingDialog, setOpenOnboardingDialog] = useState(false);

  const handleCloseOnboardingDialog = () => {
    setOpenOnboardingDialog(false);
    setOnboardingData("export", folder.data.documentID[0], false);
  };
  const startWatchVideo = () => {
    setOnboardingData("export", folder.data.documentID[0], true);
  };
  const getSnackbar = (message, status) => {
    enqueueSnackbar(
      <Typography variant="body2" className="snackbar-msg">
        {t(message)}
      </Typography>,
      {
        variant: status,
        autoHideDuration:
          status === "error" ? null : displayOaboarding ? 1000 : 5000,
      },
    );
  };
  let displayOaboarding =
    (folder?.data?.type?.type === "Manuscript" ||
      folder?.data?.type?.type === "oldDoc") &&
    !user.data.onboardingData.exportOnboarding.isChecked &&
    !user.data.onboardingData.exportOnboarding.documentId.includes(
      folder?.data?.documentID[0],
    );

  useEffect(() => {
    if (socketData?.body?.downloadUrl && startExport) {
      const element = document.createElement("a");
      element.href = socketData?.body?.downloadUrl;
      document.body.appendChild(element);
      element.click();
      startExportPdf(false);
      setSocketData([]);
      resetReceivedObjects();
      resetFailedReceivedObjects();
      getSnackbar("successExport", "success");
      if (displayOaboarding) {
        setOpenOnboardingDialog(true);
      }
    }
  }, [socketData, startExport]);

  useEffect(() => {
    if (socketData?.body?.downloadUrl && !startExport) {
      setSocketData([]);
      resetReceivedObjects();
      resetFailedReceivedObjects();
      startExportPdf(null);
    }
  }, [socketData, startExport]);

  useEffect(() => {
    if (
      socketData?.body?.Status === "Fail" &&
      socketData?.body?.Type === "export"
    ) {
      getSnackbar("errorExportPdf", "error");
      setSocketData([]);
      resetReceivedObjects();
      resetFailedReceivedObjects();
      startExportPdf(null);
    }
  }, [socketData]);

  useEffect(() => {
    if (folder?.data?.exportStatus === "In progress") {
      startExportPdf(true);
    }
  }, [folder]);

  return (
    <>
      <Helmet>
        <title>{t(children.props.pagetitle)}</title>
      </Helmet>
      <OnboardingDialog
        onApply={handleCloseOnboardingDialog}
        onClose={handleCloseOnboardingDialog}
        open={openOnboardingDialog}
        startWatchVideo={startWatchVideo}
      />
      <div className="layout-container">
        {page !== "EditorView" ? <Header /> : null}
        <Container
          component="main"
          disableGutters={page === "EditorView"}
          maxWidth={page === "EditorView" ? "100%" : "lg"}
          sx={{
            margin: page && "auto",
          }}
        >
          {children}
        </Container>
        {page !== "EditorView" && <Footer />}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
